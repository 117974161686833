/* Styles globaux */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #212121;
  cursor: cell;
}

/* Topbar */
.topbar {
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 7vh;
  padding: 10px;
  color: #212121;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.logo-topbar {
  width: 60%;
  max-width: 300px;
}

.button-container-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-topbar {
  background-color: #ffffff;
  border: none;
  color: #212121;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: calc(0.7rem + 1vmin);
  font-weight: bold;
  cursor: cell;
  border-radius: 10px;
}

.button-topbar:hover {
  background-color: #e0e0e0;
}

/* HOME */

.Home {
  padding-top: 7vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-height: 93vh;
}

.left-home {
  width: 50%;
  height: 93vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: right;
  padding-left: 100px;
}

.right-home {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;
  background-image: url('./Group\ 17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  mask-image: linear-gradient(to right, transparent, black, black, black, black);
}

@media (max-width: 950px) {
  .Home {
    flex-direction: column;
  }
  .left-home,
  .right-home {
    width: 100%;
    padding-left: 0px;
  }
  .right-home {
    display: none;
  }
}

.left-container {
  width: 90%;
  margin: auto;
  text-align: left;
  padding: 10px;
}

.right-container {
  width: 90%;
  margin: auto;
  text-align: center;
  padding: 10px;
}

.title {
  font-size: calc(2rem + 1.5vmin);
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: calc(1rem + 0.8vmin);
  color: #666;
  margin-bottom: 50px;
}

.button-discover-our-solutions {
  width: 50%;
  background-color: #212121;
  border: 1px solid #212121;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: calc(0.7rem + 1vmin);
  font-weight: bold;
  cursor: cell;
  border-radius: 10px;
}

.button-discover-our-solutions:hover {
  background-color: #ffffff;
  color: #212121;
}

/* ME */

.discover-me {
  box-shadow: 0px 0px 1px 0px #212121;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 93vh;
}

.intro-discover-me {
  padding-top: 7vh;
  width: 70%;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.discover-me-title {
  font-size: calc(1.5rem + 1vmin);
  font-weight: bold;
  margin-bottom: 4vh;
}

.discover-me-subtitle {
  font-size: calc(0.8rem + 0.6vmin);
  color: #666;
}

.subtitle-discover {
  font-size: calc(1rem + 0.8vmin);
  color: #666;
}

.all-discover-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
  padding-bottom: 5vh;
  margin: 0 auto;
}

.meAsImg {
  max-width: 300px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 950px) {
  .all-discover-container {
    flex-direction: column;
  }
  .meAsImg {
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.left-discover {
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: center;
}

.right-discover {
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: center;
}

.left-container-discover {
  width: 90%;
  height: 90%;
  margin: auto;
  text-align: center;
  padding: 10px;
  background-color: #fff;
}

@media (max-width: 950px) {
  .discoverUs {
    width: 90%;
  }
  .left-container-discover {
    width: 100%;
    padding: 3px;
  }
}

.right-container-discover {
  width: 90%;
  height: 90%;
  margin: auto;
  text-align: center;
  padding: 10px;
}

.title-discover {
  font-size: calc(1.5rem + 1vmin);
  font-weight: bold;
}

.subtitle-discover {
  font-size: calc(1rem + 0.8vmin);
  color: #666;
}

/* SOLUTIONS */

.Our-solutions {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7vh;
  padding-bottom: 7vh;
  /* min-height: 93vh; */
}

.intro-solutions {
  /* margin-top: 7vh; */
  width: 70%;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.solutions-title {
  font-size: calc(1.5rem + 1vmin);
  font-weight: bold;
  margin-bottom: 4vh;
}

.solutions-subtitle {
  font-size: calc(0.8rem + 0.6vmin);
  color: #666;
}

.produits {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productWrapper {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.product {
  position: relative;
  width: 200px;
  height: 200px;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #212121;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.card-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: top 0.3s;
}

.center-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.product:hover .text-container {
  top: -30%;
}

.product:hover .center-text {
  opacity: 1;
}

.moreAbout-button {
  background-color: aliceblue;
  border-radius: 5px;
  width: 150px;
  padding: 10px;
}

.productWrapper2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.product2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #ffffff;
  width: 200px;
  height: 50px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.hovered2 .overlay2 {
  opacity: 0;
}

.hovered2 .productText2 {
  transform: translateY(100%);
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
  opacity: 1;
}

.productText2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  margin: 1rem;
  border-radius: 20px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  transition: transform 0.3s ease;
  transform: translateY(0%);
}

.produits2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.they-trust-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: aliceblue;
}

.they-trust-us {
  margin-top: 4vh;
  border: 1px solid #F0F0F2;
  width: 80%;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 7vh;
  transition: 0.3s ease-in-out;
}

.they-trust-us:hover {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.they-trust-us-title {
  font-size: calc(1rem + 1vmin);
  color: #666;
  margin-bottom: 10px;
}

/* ABOUT US */

.contact-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 60vh;
  padding-bottom: 5vh;
}

.all-about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
  padding-bottom: 5vh;
  margin: 0 auto;
}

.meAsImg {
  max-width: 300px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.meAsImg2 {
  max-width: 300px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 150px;
}

@media screen and (max-width: 950px) {
  .all-about-container {
    flex-direction: column;
  }
  .meAsImg {
    max-width: 200px;
    margin-bottom: 20px;
  }
  .meAsImg2 {
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.left-about {
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: center;
}

.right-about {
  width: 100%;
  flex-direction: column;
  display: flex;
  text-align: center;
}

.left-container-about {
  width: 90%;
  height: 90%;
  margin: auto;
  text-align: center;
  padding: 10px;
  background-color: #fff;
}

@media (max-width: 950px) {
  .aboutUs {
    width: 90%;
  }
  .left-container-about {
    width: 100%;
    padding: 3px;
  }
}

.right-container-about {
  width: 90%;
  height: 90%;
  margin: auto;
  text-align: center;
  padding: 10px;
}

.title-about {
  font-size: calc(1.5rem + 1vmin);
  font-weight: bold;
}

.subtitle-about {
  font-size: calc(1rem + 0.8vmin);
  color: #666;
}

.buttons-contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 950px) {
  .buttons-contact-container {
    flex-direction: column;
  }
}

.new-button-contact {
  margin: 15px;
  width: 100%;
  background-color: #212121;
  border: 1px solid #212121;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: calc(0.7rem + 1vmin);
  font-weight: bold;
  cursor: cell;
  border-radius: 10px;
}

/* FOOTER */

.footer {
  width: 100%;
  background-color: aliceblue;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerText {
  font-size: calc(0.8rem + 0.2vw);
  margin: 2vh;
  cursor: cell;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
